import {Component, Input, OnInit} from '@angular/core';
import {
  LinkUser,
  Organisation,
  Role,
  Team,
  UpdateUser,
  UserProfile
} from '@flux-capacitor-io/flux-host-typescriptmodels';
import {Handler} from "../../../common/handler";
import {View} from "../../../common/view";

@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.scss']
})
@Handler()
export class UserDetailsComponent extends View implements OnInit {
  authorisationRoles: Role[] = [Role.owner, Role.developer];
  @Input() data: UserProfile;
  isNew: boolean;
  organisation: Organisation;
  email: string;
  role: Role;

  ngOnInit(): void {
    if (!this.data.userId) {
      this.isNew = true;
      this.sendQuery("getOrganisation").subscribe(o => this.organisation = o);
    }
  }

  updateInfo = () => {
    if (this.data.systemUser) {
      if (this.isNew) {
        this.sendQuery("getTeam").subscribe((t: Team) => {
          this.sendCommand("host.flux.service.authentication.api.CreateTeamSystemUser", {
            teamId: t.teamId,
            name: this.data.details.firstName
          });
        });
      } else {
        const command: UpdateUser = {
          userId: this.data.userId,
          details: this.data.details
        }
        this.sendCommand("host.flux.service.user.api.UpdateUser", command);
      }
    } else {
      if (this.isNew && this.organisation) {
        const command: LinkUser = {
          organisationId: this.organisation.organisationId,
          email: this.email,
          role: this.role
        }
        this.sendCommand("host.flux.service.authentication.api.LinkUser", command);
      }
    }
  }
}
